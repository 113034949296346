import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { CoreConfigService } from "@core/services/config.service";
import { ActivatedRoute } from "@angular/router";
import { PasswordValidator } from "app/shared/shared-functions";
import { ForgetPasswordService } from "app/services/forget-password.service";
import { NotificationService } from "app/services/toast.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  // Public
  public emailVar;
  public coreConfig: any;
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public id
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   *
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private forgetPasswordService: ForgetPasswordService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    // this._coreConfigService.config = {
    //   layout: {
    //     navbar: {
    //       hidden: true,
    //     },
    //     menu: {
    //       hidden: true,
    //     },
    //     footer: {
    //       hidden: true,
    //     },
    //     customizer: false,
    //     enableLocalStorage: false,
    //   },
    // };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (
      this.resetPasswordForm.invalid &&
      this.resetPasswordForm.value.password ==
        this.resetPasswordForm.value.confirmPassword
    ) {
      return;
    }
    let form = {
      oldPassword: this.resetPasswordForm.value.actualPassword,
      newPassword: this.resetPasswordForm.value.password,
    };
    this.forgetPasswordService
      .resetPassword(this.id,form)
      .subscribe(
        (data) => {
          this.notificationService.showSuccess(
            "Mot de passe est mis à jour avec succès",
            "Succès"
          );
        },
        (error) => {
          this.notificationService.showError(error, "Erreur");
        }
      );
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      actualPassword: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          PasswordValidator(8, true, true, true, true),
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          PasswordValidator(8, true, true, true, true),
        ],
      ],
      confirmPassword: ["", [Validators.required]],
    });
    this.id = this.activatedRoute.snapshot.params.id
    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

