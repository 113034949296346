export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      HOME: 'Maison',
      SAMPLE: 'Échantillon',
      CHAT: 'Chat'
    }
  }
}
