import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CoreCommonModule } from "@core/common.module";

import { AuthForgotPasswordV2Component } from "./auth-forgot-password-v2/auth-forgot-password-v2.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { AuthLoginV2Component } from "./auth-login-v2/auth-login-v2.component";

// routing
const routes: Routes = [
  {
    path: "login",
    component: AuthLoginV2Component,
    data: { animation: "auth" },
  },
  {
    path: "forgot-password",
    component: AuthForgotPasswordV2Component,
  },
  {
    path: "reset-password/:id",
    component: ResetPasswordComponent,
  },
];

@NgModule({
  declarations: [
    AuthLoginV2Component,
    AuthForgotPasswordV2Component,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
  ],
})
export class AuthenticationModule {}
